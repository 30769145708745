import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Footer from './components/Footer';
import {Navbar} from './components/Navbar';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import {Home} from './pages/Home';
import Products from './pages/Products';
import About from './pages/About';
import Team from './pages/Team';
import HomeContact from './components/HomeContact';

export default function App() {
  return (
  <Router>
    <Navbar/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/team" element={<Team/>} />
      <Route path="/about" element={<About/>} />
      {/* <Route path="/blog" element={<Blog />} /> */}
      <Route path="/contact-us" element={<HomeContact />} />
    </Routes>
    <Footer/>
  </Router>
  )
}

