import React from 'react'
import homeBackground from '../assets/home-bg.jpg'

const HomeHeader = () => {
    return (
    <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
        <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
          <svg
            className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
            viewBox="0 0 100 100"
            fill="currentColor"
            preserveAspectRatio="none slice"
          >
            <path d="M50 0H100L50 100H0L50 0Z" />
          </svg>
          <img
            className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
            src={homeBackground}
            alt=""
          />
        </div>
        <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
          <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
            <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-color-secondary sm:text-4xl sm:leading-none">
              Welcome to
              <br className="hidden md:block" />
              <span className="inline-block text-color-primary text-4xl ">
                Swab Nanosolutions
              </span>
            </h2>
            <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
              SWAB Nanosolutions develops and commercialize nanotechnology based solutions, consumables and application for the Industries.​ As an aware global citizen, We believe in making tomorrow better for future generations.{"\n"}
              <br></br>
              SWAB Nanosolutions is building a preeminent global R&D based industry focused on the discovery, development and commercialization of innovative technologies for industries and market.
            </p>
          </div>
        </div>
    </div>
    )
}

export default HomeHeader
