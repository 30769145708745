import React from 'react'
import Data from '../data/Data'

const HomeCollabs = () => {
    return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            Our Collaborations
            </h2>
          <p className="text-base text-gray-700 md:text-lg">
            "Alone we can do little but together we can do so much more." <br></br>
            Swab is bridging the gap between Academic and Industrial research by collaborating with premier institutes and industries globally. We believe in implementing the 3 C's 
            (coherence, cooperation and communication) for addressing the problem with togetherness.
          </p>
        </div>
        <div className="grid gap-10 mx-auto lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-3">
            {Data.collaborations.map((item,index) => (
            <div className="flex flex-col items-center">
                <img
                    className="object-cover w-20 h-20 mb-2 rounded-full"
                    src={item.imageUri}
                    alt="Person"
                />
                <div className="flex flex-col items-center">
                    <p className="text-lg font-bold">
                        {item.title}
                    </p>
                    <p className="text-sm text-gray-800">
                        {item.subtitle}
                    </p>
                </div>
            </div>
            ))}

        </div>
    </div>
    )
}

export default HomeCollabs
