import React from 'react'
import stepImage from '../assets/step.jpg'
import aboutFirst from '../assets/about-1.jpg'
import aboutSecond from '../assets/about-2.jpg'
import aboutThird from '../assets/about-3.jpg'
import Data from '../data/Data'
import { ArrowDownward, Done } from '@mui/icons-material'

const About = () => {


    return (
    <>
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    About Company
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    At Swab, Founders believe that we are 'humans in progress'.
                </p>
            </div>
            <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
                <div className="grid grid-cols-2 gap-5">
                <img
                    className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
                    src={aboutFirst}
                    alt=""
                />
                <img
                    className="object-cover w-full h-48 rounded shadow-lg"
                    src={aboutSecond}
                    alt=""
                />
                <img
                    className="object-cover w-full h-48 rounded shadow-lg"
                    src={aboutThird}
                    alt=""
                />
                </div>
                <div className="flex flex-col justify-center">
                    <div className="pb-4 mb-4 border-b">
                        <h6 className="mb-5 font-semibold leading-5">
                           {Data.aboutQuote}
                        </h6>
                        <p className="text-sm text-gray-900">
                            Swab Nanosolutions was founded by two young individuals, Dr. Sweta Bhansali and Abhinav Sirothia in 2019, with an aim to bring out science and technology from labs to your houses. Two young scientists after finishing their PhD and Master’s from prestigious universities of Europe, came together with a same goal of serving the society and nation by their knowledge and passion.<br></br>
                            We at Swab Nanosolutions, aim to find solutions to your day to day problems by our expertise in the field of Nanotechnology. We are based in Delhi/NCR but we have global outreach with our international connections ranging from academia to Industries.<br></br>
                            We aim to provide innovations based as per the need of the customers and the market.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                <div className="lg:py-6 lg:pr-16">
                    {
                        Data.aboutSteps.map((item,index) => (
                            <div className="flex">
                                <div className="flex flex-col items-center mr-4">
                                <div>
                                    <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                                        <ArrowDownward/>
                                    </div>
                                </div>
                                <div className="w-px h-full bg-gray-300" />
                                </div>
                                <div className="pt-1 pb-8">
                                <p className="mb-2 text-lg font-bold">Step {index+1} : {item.title}</p>
                                <p className="text-gray-700">
                                    {item.subtitle}
                                </p>
                                </div>
                            </div>
                        ))
                    }
                
                <div className="flex">
                    <div className="flex flex-col items-center mr-4">
                    <div>
                        <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        <Done/>
                        </div>
                    </div>
                    </div>
                    <div className="pt-1">
                    <p className="mb-2 text-lg font-bold">Success</p>
                    <p className="text-gray-700">
                        Finally success in maintaining the relationship between nature and nanotechnology.
                    </p>
                    </div>
                </div>
                </div>
                <div className="relative">
                <img
                    className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute lg:h-full"
                    src={stepImage}
                    alt=""
                />
                </div>
            </div>
        </div>



        


    </>
    )
}

export default About
