import React from 'react'
import Data from '../data/Data'

const Products = () => {
    return (
            Data.products.map((item,index) => (
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
                  <div className={`flex flex-col justify-center order-${index % 2 == 0 ? '1' : '2'}`}>
                    <div className="max-w-xl mb-6">
                      <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-blue-gray-900 sm:text-4xl sm:leading-none">
                        {item.title}
                        <br className="hidden md:block" />
                      </h2>
                      <p className="text-base text-gray-700 md:text-md">
                        {item.description}
                      </p>
                    </div>
                  </div>
                  <div className={`order-${index % 2 == 0 ? '2' : '1'}`}>
                    <img
                      className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                      src={item.imageUri}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ))  
    )
}

export default Products
