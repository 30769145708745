import React from 'react'
import Data from '../data/Data'
import Icon from '@mui/material/Icon';

const HomeServices = () => {
    return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Our Services
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          True service with commitment and integrity.
        </p>
      </div>
      <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
        {
          Data.services.map((item,index) => (
          <div className="sm:text-center">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-color-light sm:mx-auto sm:w-24 sm:h-24">
              <Icon style={{fontSize:'3rem'}}>{item.iconName}</Icon>
            </div>
            <h6 className="mb-4 font-bold leading-5 text-2xl">
              {item.title}
            </h6>
            <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto text-justify" >
              {item.description}
            </p>
          </div>
          ))
        }
      </div>
    </div>
    )
}

export default HomeServices
