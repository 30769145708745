import React from 'react'
import Carousel from 'react-material-ui-carousel'
import Data from '../data/Data'


const HomeContact = () => {
    return (
    <div className="relative">
      <img
        src="https://cdn.pixabay.com/photo/2018/02/27/14/58/crystal-grid-3185671_1280.png"
        className="absolute inset-0 object-cover w-full h-full"
        alt=""
      />
      <div className="relative bg-color-primary bg-opacity-20">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="flex flex-col items-center justify-between xl:flex-col">
            <div className="w-full align-middle justify-center flex mb-6">
              <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
                Contact Us
              </h1>
            </div>
            <div className="w-full sm:w-full">
              <div className="bg-white rounded shadow-2xl p-7 sm:p-10">
                <Carousel interval={9000}>
                      {
                          Data.contactAddress.map( (item, i) => (
                              <div>
                                  <iframe src={item.location} width="100%" style={{border:0,minHeight:500}} allowfullscreen="" loading="lazy"></iframe>
                                  <p className="max-w-xl mt-10  text-color-primary sm:mx-auto font-bold">{item.address}</p>
                              </div>

                          ))
                      }
                  </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}

export default HomeContact
