import React from 'react'
import { Link } from 'react-router-dom'
import Data from '../data/Data'

const HomeProducts = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-6 sm:mx-auto sm:text-center md:mb-10 lg:max-w-2xl">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Our Products
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Exploring Nano dimension for solutions.
        </p>
      </div>
      <div className="grid max-w-screen-lg gap-8 row-gap-5 md:row-gap-8 sm:mx-auto lg:grid-cols-2">
        {
          Data.products.map((item,index) => (
            <div className="transition duration-300 transform bg-white rounded shadow-sm hover:-translate-y-1 hover:shadow md:text-center">
              <div className="relative">
                <img
                  className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                  src={item.imageUri}
                  alt=""
                />
                <div className="absolute inset-0 bg-gray-800 bg-opacity-25" />
              </div>
              <div className="px-6 py-8 border border-t-0 rounded-b sm:px-8">
                <h5 className="mb-2 text-xl font-bold leading-none sm:text-2xl">
                  {item.title}
                </h5>
                <p className="mb-5 text-gray-700 text-justify truncate max-w-md">
                  {item.description}
                </p>
      
                  <button
                    type="submit"
                    onClick={() => window.location.href="/products"}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-color-primary hover:bg-color-secondary focus:shadow-outline focus:outline-none"
                  >
                    Read more
                  </button>
                
              </div>
            </div>
          ))
        }

      </div>
    </div>
    )
}

export default HomeProducts
