//products
import product1 from "../assets/product1.jpg";
import product2 from "../assets/product2.jpg";
import product3 from "../assets/product3.jpg";
import product4 from "../assets/product4.jpg";
//team
import team1 from "../assets/team1.png";
import team2 from "../assets/team2.jpeg";
import team3 from "../assets/team3.jpeg";
import team4 from "../assets/team4.jpeg";
import team5 from "../assets/team5.jpeg";
import team6 from "../assets/team6.jpeg";
import team7 from "../assets/team7.jpeg";
import team8 from "../assets/team8.png";
//collaborations
import collab1 from "../assets/collab1.png";
import collab2 from "../assets/collab2.jpg";
import collab3 from "../assets/collab3.png";
import { Facebook, LinkedIn } from "@mui/icons-material";

export default {
  //HomeHeader
  companyName: "Swab Nanosolutions",
  companyDescription:
    "SWAB Nanosolutions develops and commercialize nanotechnology based solutions, consumables and application for the Industries.​ As an aware global citizen, We believe in making tomorrow better for future generations.\n\nSWAB Nanosolutions is building a preeminent global R&D based industry focused on the discovery, development and commercialization of innovative technologies for industries and market.",
  companyImage:
    "https://base.imgix.net/files/base/ebm/wto/image/2019/04/WT0519_iStock-970829836_koya79.png?auto=format&w=720",

  //HomeAbout

  aboutQuote:
    '"BEST IDEAS AND RESULTS COME WHEN TWO PASSIONATE INDIVIDUALS WITH THE SIMILAR GOALS OF SERVING THE SOCIETY AND NATURE, UNITE TOGETHER AS A TEAM."',
  aboutText:
    "Swab Nanosolutions was founded by two young individuals, Dr. Sweta Bhansali and Abhinav Sirothia in 2019, with an aim to bring out science and technology from labs to your houses., Two young scientists after finishing their PhD and Master’s from prestigious universities of Europe, came together with a same goal of serving the society and nation by their knowledge and passion.\n\nWe at Swab Nanosolutions, aim to find solutions to your day to day problems by our expertise in the field of Nanotechnology. We are based in Delhi/NCR but we have global outreach with our international connections ranging from academia to Industries.\n\nWe aim to provide innovations based as per the need of the customers and the market.",

  socialMediaLinks: [
    {
      iconName: <Facebook />,
      url: "https://www.facebook.com/Swab.nanosolutions/",
    },
    {
      iconName: <LinkedIn />,
      url: "https://www.linkedin.com/company/swabnanosolutions",
    },
  ],

  services: [
    {
      iconName: "supervisor_account",
      title: "Consultancies",
      description:
        "With the expertise and knowledge we have in the area of nanotechnology, and earning to learn more how we can solve the issues in the products of other industries, swab provides consultancies and R&D solutions for various nanotech based applications and products. Our knowledge will help our clients to have better products.The excellent knowledge of swab will help the other industries to benefit from our consultancies in order to develop the final product they would have envisioned. This will be done with proper analysis of the problems the industry would be facing and helping them out in finalising the product with better properties via addition of nanotechnology.",
    },
    {
      iconName: "eco",
      title: "Green nanotechnology",
      description:
        "A clean and sustainable environment is what we want to live in and swab aims to work towards building exactly that with its R&D services and products. Utilising the concept of green chemistry and green engineering we are planning to bring products which can be used to reduce the effects of fuels and chemicals on the environment and human health.With more renewable sources, reduced waste and biomimetics into use Swab is working hard to provide cost effective solutions to all the nano enhanced products such as applications in cosmetics, coatings, energy, medicine and nano biotechnology.",
    },
    {
      iconName: "public",
      title: "Collaborations",
      description:
        "We believe in the motto of “together we can”.  With the excellence in our R&D developed product we ought to partner with academic institutions and industries for brainstorming and making ideas into reality. We are working on the lines of bringing the gap between academic and industrial research and let people experience nanotechnology based products on the larger scale and in more applications than that already exists. We will be partnering with industries for manufacturing purpose of our product line and with academic institutions to share the knowledge via workshops and webinars and have funding proposals by collaborating with them.",
    },
  ],

  products: [
    {
      imageUri: product1,
      title: "Protective nanocoatings",
      description:
        "With expertise in the synthesis of nanotechnology based protective coatings, Swab researchers are determined to provide nanosolutions by tailoring the nanomaterials with different properties like size and morphology for the enhancement of the product. Our expertise can cater for production of protective coatings for various applications such as nanocoatings for textiles, paints, furnitures, horticulture, footwear, hand bags and automotive parts.\n\nOur nanomaterials enhanced functionalities over the traditional coatings will include extending the shelf life of fruits and vegetables, increasing the durability, properties and quality of textiles and increasing the durability of the wooden surface against the external agents",
    },
    {
      imageUri: product2,
      title: "Smart Futuristic Materials",
      description:
        'As a core nanotech company working for the materials that can not only be efficient but also can solve problems for humanity as well as environment, we are working on developing “intelligent materials” or "smart materials" which works on the principle of  manifesting their own functionality as a response to environmental changes at the most optimum conditions. We believe new requirements and new needs in the world need new and smart materials that could be stimuli responsive or can function according to the environment they are in. Swab is working and developing these challenging nanomaterials and nanocomposites for applications ranging from medicine to energy.',
    },
    {
      imageUri: product3,
      title: "Solar Energy",
      description:
        "Swab nanosolutions strive hard to contribute in the energy efficient solutions which will be  saviour for the world energy problems. To make the world solar and thermoelectric power friendly as times are coming we will have to depend on the renewable sources of energy source i.e. sun and wasted heat from vehicles and industries.We will be working closely with the industries to collaborate and provide the solar energy to the ruralist of the areas possible so that everyone can get light.\n\nOur Nanotechnology enhanced solutions will be taking care of \n1)Efficiency of the panel\n2)Cost effectiveness\n3)Self cleaning capabilities \n4)Making world free form greenhouse gases and pollution",
    },
    {
      imageUri: product4,
      title: "Advanced Intelligent nanomaterials ",
      description:
        "At Swab we focus on synthesis of advanced intelligent nanomaterials and investigation of their properties.We are manufacturer of metallic nanoparticles (Ag, Au), Biopolymers nanoparticles, Graphene and its deriviatives. Keeping the sustainbility of the environment in mind, we also focus on green synthesis of smart nanomaterials.\n\nThe versality of these smart nanomaterials and their composition can impart multifunctional, or one or combination of the following properties– \n1)Hydrophobitcity and Hydrophillcity\n2)Self cleaning/ Self healing\n3)Anti-microbial properties\n4)Antistatic, Anti- UV and Anti reflective properties\n5)Scratch resistance",
    },
  ],

  coreMembers: [
    {
      imageUri: team1,
      title: "Dr. Sweta Bhansali",
      subtitle: "Researcher",
      description:
        "A spiritual soul who is a PhD and entrepreneur in nanotechnology. Phd in materials science with experience in fabrication of nano materials specifically nano composites for transparent conductive electrodes and thin films and superlattices through PVD processes for Thermoelectrics. Expertise in electrical and thermal measurements of thin film systems along with its microscopic and structural properties using XRD, SEM and HRTEM. Due to her passion to bring something useful and her work done on Nanomaterials during her Ph.D she was awarded young scientist award by European materials research society. she loves being volunteer and motivational mentor with NGOs mentor together, FEA, Naam Vidhaikalam of Tamil Nadu and SICAR in Spain. Her passions include Travelling, reading, writing poems and being a volunteer for spreading love and smiles and going for adventurous sports. she is also a NLP Practitioner and a toastmaster. she believes in working for humanity and well being of environment through her research and social work.",
      specialities: [
        "Green nanomaterials and nanocomposites fabrication",
        "Nano-composites for transparent electrode",
        "Nanomaterials for thermoelectricity",
        "Smart nanomaterials fabrication",
      ],
    },
    {
      imageUri: team2,
      title: "Abhinav Sirothia",
      subtitle: "Researcher",
      description:
        "A young scientist and an entrepreneur with a spiritual soul. He has served several reputed institutes - DRDO and TNO in the Netherlands. He has a track record of working in the field of synthesis and characterization of nanomaterials. He has been working on a position funded by European FP7 programs. He believes in bridging the gap between Academics and Industry. He also has experience of working in a pharma industry and responsibly understands the ethics and conduct of Industrial work culture. He manages the operations of World Spiritual Foundation, an expert in managing interpersonal relationships with International clients. He loves to travel, explore new opportunities, learn from others and live a happy and purposeful life.",
      specialities: [
        "Green Nanotechnology",
        "Nanomaterials based sensors",
        "Biopolymer nano composites synthesis",
        "Quantum Dots",
      ],
    },
  ],

  team: [
    {
      imageUri: team4,
      title: "Alan Ali",
      subtitle: "International Business Executive",
      description:
        "International Business Executive (International Business and Relationships)",
      socialMediaLinks: [
        {
          iconName: <LinkedIn />,
          url: "",
        },
      ],
    },
    {
      imageUri: team6,
      title: "Dr Emigdio Chavez",
      subtitle: "Scientific Advisor",
      description: "",
      socialMediaLinks: [
        {
          iconName: <LinkedIn />,
          url: "https://www.linkedin.com/in/emigdio-chavez-angel-0a487949/?originalSubdomain=es",
        },
      ],
    },
    // {
    //     imageUri:team5,
    //     title:'Jonah Barnstein',
    //     subtitle:'Strategy Advisor',
    //     description:'',
    //     socialMediaLinks:[
    //         {
    //             iconName:<LinkedIn/>,
    //             url:"https://www.linkedin.com/in/jonahbernstein/"
    //         },
    //     ]
    // },
    {
      imageUri: team7,
      title: "Nivesh Choraria",
      subtitle: "Accounts & Finance",
      description: "",
      socialMediaLinks: [
        {
          iconName: <LinkedIn />,
          url: "",
        },
      ],
    },
    {
      imageUri: team3,
      title: "Jagan KSG",
      subtitle: "Researcher",
      description: "",
      socialMediaLinks: [
        {
          iconName: <LinkedIn />,
          url: "",
        },
      ],
    },
    {
      imageUri: team8,
      title: "Dr. Tinku Basu",
      subtitle: "Distinguished Scientific Advisor",
      description: "",
      socialMediaLinks: [
        {
          iconName: <LinkedIn />,
          url: "https://www.linkedin.com/in/tinku-basu-80647845/",
        },
      ],
    },
  ],

  collaborations: [
    {
      imageUri: collab1,
      title: "KSR college of Technology",
      subtitle: "",
    },
    {
      imageUri: collab2,
      title: "Amity University",
      subtitle: "",
    },
    {
      imageUri: collab3,
      title: "PSG college of technology",
      subtitle: "",
    },
  ],

  aboutSteps: [
    {
      title: "Problems",
      subtitle:
        "To look out for the hidden problems in the solutions or the products that already exists.",
    },
    {
      title: "Solutions",
      subtitle:
        "Thinking of the best and working deliverable to solve the existing problem.",
    },
    {
      title: "Prototype",
      subtitle:
        "Designing and providing the solution according to the need of the customer and their business for the problem to be solved or the betterment of the existing technology.",
    },
    {
      title: "Deliverable",
      subtitle:
        "Making our customers happy to use our nanosolutions to make their existing product more better and environmental friendly.",
    },
  ],

  contactAddress: [
    {
      title: "Office 1",
      address:
        "SWAB NANOSOLUTIONS PRIVATE LIMITED Khasra NO. 38/10, Vill: Mundka, Near Mundka Metro Yard New Delhi, Delhi 110041, India",
      location:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.254245134148!2d76.99984081508373!3d28.682040282398326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0f5a93642f25%3A0xc8ed28cea9ceeaef!2sMundka%20Udyog%20Nagar%2C%20Mundka%20Industrial%20Area%2C%20Mundka%2C%20Delhi%2C%20110081!5e0!3m2!1sen!2sin!4v1642064947571!5m2!1sen!2sin",
    },
    {
      title: "Office 2",
      address:
        "Swab Nanosolutions, 4th floor, J block Bionest, PSG step, PSG college of Technology, Peelamadu, Avinashi road, Coimbatore (T.N.) - 641004",
      location:
        "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3916.184343170766!2d76.9990501!3d11.0247918!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8582f031fca27%3A0x774c312c589c8103!2sC%20Block%2C%20psg%20tech%2C%20Avinashi%20Rd%2C%20Peelamedu%2C%20Coimbatore%2C%20Tamil%20Nadu%20641004!5e0!3m2!1sen!2sin!4v1642065043840!5m2!1sen!2sin",
    },
  ],
};
