import React from 'react'
import Data from '../data/Data'

const HomeAbout = () => {
    return (
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto sm:text-center lg:max-w-2xl">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              About Us
            </h2>
            <p className="text-base text-gray-700 md:text-md">
              "BEST IDEAS AND RESULTS COME WHEN TWO PASSIONATE INDIVIDUALS WITH THE SIMILAR GOALS OF SERVING THE SOCIETY AND NATURE, UNITE TOGETHER AS A TEAM."
            </p>
          </div>
          <div className="mb-4 transition-shadow duration-300 hover:shadow-xl lg:mb-6">
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-64 md:h-80 lg:h-96"
              src="https://cdn.pixabay.com/photo/2017/02/01/13/52/analysis-2030261_1280.jpg"
              alt=""
            />
          </div>
          <p className="max-w-xl mb-4 text-base text-gray-700 sm:mx-auto">
            At Swab, Founders believe that we are 'humans in progress'.
          </p>
          <a
            href="/about"
            aria-label=""
            className="inline-flex items-center font-semibold transition-colors duration-200 text-color-primary hover:text-color-secondary"
          >
            Learn more
            <svg
              className="inline-block w-3 ml-2"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
            </svg>
          </a>
        </div>
      </div>
        )
    }

export default HomeAbout
