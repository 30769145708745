import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import HomeHeader from '../components/HomeHeader';
import HomeAbout from '../components/HomeAbout';
import HomeServices from '../components/HomeServices';
import HomeProducts from '../components/HomeProducts';
import HomeContact from '../components/HomeContact';
import HomeCollabs from '../components/HomeCollabs';

export const Home = () => {
    return (
        <>
          <HomeHeader/>
          <HomeServices/>
          <HomeProducts/>
          <HomeCollabs/>
          <HomeAbout/>
          <HomeContact/>
        </>
    );
  };
